.App {
  min-height: 100vh;

  > .ant-layout-header {
    position: relative;
    z-index: 100;
    display: flex;
    flex-direction: row;
    box-shadow: 0 2px 8px #f0f1f2;
    .brand {
      flex: 0 0 auto;
      padding: 0 40px 0 48px;
      font-size: 18px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      background: url(../../images/logo.png);
      background-size: 32px 32px;
      background-position: left center;
      background-repeat: no-repeat; }
    .actions {
      margin-left: auto;
      padding: 0 0 0 40px; }
    > .ant-menu {
      border: none; } }

  > .ant-layout-footer {
    text-align: center; }

  .padding-0 {
    padding: 0; } }
